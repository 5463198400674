<template>
  <vx-card :title="title">
    <div class="vx-row">
      <div class="vx-col">
        <!-- back -->
        <div class="vx-row mb-6">
          <div class="vx-col w-1/8">
            <vs-button
              @click="onClickBtnBack"
              class="w-full"
              color="danger"
              type="filled"
            >
              Back
            </vs-button>
          </div>
        </div>
      </div>
    </div>
    <!-- header info -->
    <Header
      :salesman="header.salesman"
      :date="header.date"
      :territory="header.territory"
      :operating_unit="header.operating_unit"
    />

    <!-- table -->
    <datatable :data="datatable" />
  </vx-card>
</template>

<script>
import Header from "./_components/header";
import Datatable from "./_components/datatable";

export default {
  components: {
    Header,
    Datatable,
  },
  data() {
    return {
      title: "Detail Customer Visit",
      baseUrl: "/api/sfa/v1/monitoring-dashboard/customer-visit-dashboard/show",
      datatable: null,
      header: {
        salesman: "",
        date: "",
        start_route: "",
        end_route: "",
      },
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    onClickBtnBack() {
      this.$router.push({ name: "monitoring-dashboard-sfa" });
    },
    async getData() {
      this.$vs.loading();
      try {
        const resp = await this.$http.get(this.baseUrl, {
          params: {
            start_date: this.$route.query.start_date,
            end_date: this.$route.query.end_date,
            personal_id: this.$route.query.personal_id,
          },
        });
        
        if (resp.code == 200) {
          this.datatable = resp.data
          this.header.salesman = resp.data.records.salesman
          this.header.date = resp.data.records.date
          this.header.territory = resp.data.records.territory
          this.header.operating_unit = resp.data.records.operating_unit
        }

      } catch (error) {
        this.$vs.notify({
          title: "Error",
          text: error.message,
          color: "danger",
          position: "top-right",
        });
        console.log(error)
      }
      this.$vs.loading.close()
    },
  },
 
};
</script>

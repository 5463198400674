<template>
  <div class="vx-row my-4">
    <div class="vx-col w-1/2">
      <div class="vx-row mb-4">
        <div class="vx-col sm:w-1/4 w-full flex items-center">
          <span>Salesman</span>
        </div>
        <div class="vx-col sm:w-3/4 w-full">
          <vs-input type="text" v-model="salesman" class="w-full" disabled />
        </div>
      </div>
      <div class="vx-row">
        <div class="vx-col sm:w-1/4 w-full flex items-center">
          <span>Date</span>
        </div>
        <div class="vx-col sm:w-3/4 w-full">
          <vs-input type="text" v-model="date" class="w-full" disabled />
        </div>
      </div>
    </div>
    <div class="vx-col w-1/2">
      <div class="vx-row mb-4">
        <div class="vx-col sm:w-1/4 w-full flex items-center">
          <span>Operating Unit</span>
        </div>
        <div class="vx-col sm:w-3/4 w-full">
          <vs-input type="text" v-model="operating_unit" class="w-full" disabled />
        </div>
      </div>
      <div class="vx-row">
        <div class="vx-col sm:w-1/4 w-full flex items-center">
          <span>Territory</span>
        </div>
        <div class="vx-col sm:w-3/4 w-full">
          <vs-input type="text" v-model="territory" class="w-full" disabled />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    salesman: {
      type: String,
      default: null,
    },
    date: {
      type: String,
      default: null,
    },
    operating_unit: {
      type: String,
      default: null,
    },
    territory: {
      type: String,
      default: null,
    },
  },
};
</script>
